.slick-prev:before, .slick-next:before { 
    color:var(--marketplaceColor)!important;
}

.slick-prev, .slick-next { 
    margin-top: -3pc !important;
    transform: scale(1.5) !important;
}

.slick-dots button{
    transform: scale(1.9) !important;
}

