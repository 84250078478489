.box1 {
  background-color: #d8e9e7;
  padding: 30px;
}

.box1Title {
  font-weight: 900;
  font-size: 22px;
  line-height: 24px;
}

.box1Text {
  font-size: 22px;
  line-height: 24px;
  font-weight: 400;
}

.box2 {
  background-color: #eaeaea;
  /* margin-top: 15px; */
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-radius: 10px;

  @media (max-width: 1025px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.box2Text {
  font-weight: 600;
  font-size: 20px;
  line-height: 22px;

  @media (max-width: 1025px) {
    font-size: 14px;
  }
}

.link {
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  background-color: white;
  border: 1px solid grey;
  border-radius: 30px;
  color: var(--matterColor);
  padding: 10px 25px;
  margin-left: 40px;

  @media (max-width: 1025px) {
    font-size: 14px;

    margin-top: 15px;
    margin-left: 0;
  }
}
