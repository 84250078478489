.wrapper {
  padding-bottom: 20px;
  @media (max-width: 1025px) {
    margin-top: 40px;
    padding-bottom: 0;
  }
}

.title {
  font-size: 20px;
  text-align: left;
  margin-top: 0;

  @media (max-width: 768px) {
    font-size: 18px;
    letter-spacing: 1px;
  }
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.logo {
  height: 120px;

  @media (max-width: 1378px) {
    height: 90px;
  }

  @media (max-width: 1070px) {
    height: 75px;
  }

  @media (max-width: 768px) {
    height: 60px;
  }
}
