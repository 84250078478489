.wrapper {
  @media (max-width: 1025px) {
    margin-top: 40px;
  }
}

.container {
  background-color: #eaeaea;
  display: block;
  margin-top: 15px;
  margin-bottom: 30px;
  padding: 30px 60px;
  border-radius: 10px;

  @media (max-width: 1025px) {
    margin-bottom: 10px;
    padding: 20px;
  }
}

.title {
  margin: 0 auto;
  text-align: center;
  font-size: 20px;

  @media (max-width: 1025px) {
    font-size: 18px;
  }
}

.text {
  font-weight: 400;
}

.expandButton {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  text-align: center;
  white-space: nowrap;
  border: none;
  font-weight: 600;
  cursor: pointer;
}

.iconWrapper {
  margin-left: 10px;
  background-color: #fff;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}
